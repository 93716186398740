import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import Logo from '../img/PCLOGO.png';
// import AutoComplete from './autoComplete';
import SearchAllNavlink from '../components/autoComplete/components/searchAllNavlink';

class Header extends Component {
  constructor() {
    super();

    this.state = {
      dropdown: React.createRef(),
      navbar: React.createRef(),
      searchInput: React.createRef(),
      dropdown2: React.createRef(),
      searchInput2: React.createRef(),
      // redirectToSearchResults: false,
      categories: [],
      loading: true,
      products: [],
      substring: '',
      openDropdown: false,
      searchLoading: false,
      productCount: 0,
      exceedsLimit: false,
      openNavbar: true,
      timeout: null,
      selectedProductIndex: -1,
      user: {},
      hideLoginButton: false,
      hideListsButton: false,
    };

    this.searchController = new AbortController();
    this.fetchCategoriesController = new AbortController();
    // this.showNavigation = this.showNavigation.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    // this.handleSearch = this.handleSearch.bind(this);
    this.handleClickOutsideSearch = this.handleClickOutsideSearch.bind(this);
    this.handleClickOutsideNavbar = this.handleClickOutsideNavbar.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.scrollToSelectedIndex = this.scrollToSelectedIndex.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  componentDidMount() {
    const { searchInput, searchInput2 } = this.state;
    // const { location = {} } = this.props;

    const user = {};
    user.username = localStorage.getItem('username');
    user._id = localStorage.getItem('userId');
    this.setState({
      user,
    });
    this.getCategories();
    document.addEventListener('mousedown', this.handleClickOutsideSearch);
    document.addEventListener('mousedown', this.handleClickOutsideNavbar);
    searchInput.current.addEventListener('keydown', this.handleKeyDown);
    searchInput2.current.addEventListener('keydown', this.handleKeyDown);
    // const { pathname } = location;
    // const isLoginPage = pathname.includes('/login');
    // const isRegisterPage = pathname.includes('/register');
    // const isListsPage = pathname.includes('/lists');

    // if (isLoginPage || isRegisterPage) {
    //   this.setState({
    //     hideLoginButton: true,
    //   });
    // }
    // if (isListsPage) {
    //   this.setState({
    //     hideListsButton: true,
    //   });
    // }
  }

  // componentDidUpdate() {
  //   const { redirectToSearchResults } = this.state;
  //   if (redirectToSearchResults) {
  //     this.showNavigation();
  //   }
  // }

  componentWillUnmount() {
    const { searchInput, searchInput2 } = this.state;
    document.removeEventListener('mousedown', this.handleClickOutsideSearch);
    document.removeEventListener('mousedown', this.handleClickOutsideNavbar);
    searchInput.current.removeEventListener('keydown', this.handleKeyDown);
    searchInput2.current.removeEventListener('keydown', this.handleKeyDown);
    this.setState({
      searchLoading: false,
    });
    this.searchController.abort();
    this.fetchCategoriesController.abort();
  }

  async handleClickOutsideSearch(e) {
    const {
      dropdown, searchInput, dropdown2, searchInput2, openDropdown,
    } = this.state;
    // console.log(dropdown);
    // console.log(searchInput);
    if (
      dropdown.current && searchInput.current
      && dropdown2.current && searchInput2.current
        && !dropdown.current.contains(e.target)
        && !searchInput.current.contains(e.target)
        && !dropdown2.current.contains(e.target)
        && !searchInput2.current.contains(e.target)
    ) {
      this.setState({
        openDropdown: false,
      });
    } else if (searchInput.current.contains(e.target)
      || searchInput2.current.contains(e.target)
    ) {
      this.setState({
        openDropdown: !openDropdown,
      });
    } else {
      this.setState({
        openDropdown: true,
      });
    }
  }

  async handleClickOutsideNavbar(e) {
    const {
      navbar,
    } = this.state;
    if (
      navbar.current && !navbar.current.contains(e.target)
    ) {
      this.setState({
        openNavbar: false,
      });
    }
  }

  handleKeyDown(event) {
    // const { selectedShop } = this.props;
    const { selectedProductIndex, products, substring } = this.state;
    if (event.keyCode === 13) {
      // Enter key pressed
      if (selectedProductIndex > -1 && selectedProductIndex !== products.length - 1) {
        event.preventDefault();
        const { history } = this.props;
        this.searchController.abort();
        history.push(`/product/${products[selectedProductIndex]?.slug}`);
        this.setState({
          selectedProductIndex: -1,
          substring: '',
          openDropdown: false,
          products: [],
        });
      } else if ((selectedProductIndex > -1 && selectedProductIndex === products.length - 1)
      || selectedProductIndex === -1 && substring) {
        event.preventDefault();

        const { history } = this.props;
        this.searchController.abort();
        history.push(`/products/?search=${substring}`);

        sessionStorage.removeItem('search');

        this.setState({
          selectedProductIndex: -1,
          substring: '',
          openDropdown: false,
          products: [],
        });
      }
    } else if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
      if (event.keyCode === 38) {
        // Up arrow key
        this.setState({
          selectedProductIndex: Math.max(0, selectedProductIndex - 1),
        });
        this.scrollToSelectedIndex();
      } else if (event.keyCode === 40) {
        // Down arrow key
        this.setState({
          selectedProductIndex: Math.min(
            products.length - 1,
            selectedProductIndex + 1,
          ),
        });
        this.scrollToSelectedIndex();
      }
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  onSearch(substring) {
    this.setState({ substring });
    if (substring?.length < 2) {
      this.setState({ products: [], searchLoading: false });
      if (this.searchController) {
        this.searchController.abort();
      }
      return;
    }
    let { timeout } = this.state;
    clearTimeout(timeout);
    if (this.searchController) {
      this.searchController.abort();
      // this.setState({ searchLoading: false });
      this.searchController = new AbortController();
    }

    timeout = setTimeout(() => {
      // const { redirectToSearchResults } = this.state;
      // if (redirectToSearchResults) return;
      this.setState({
        searchLoading: true,
        openDropdown: true,
      });
      fetch(`/api/products/autofill?search=${substring}`, {
        signal: this.searchController.signal,
      })
        .then((res) => res.json())
        .then((res) => {
          const {
            products, status, productCount, exceedsLimit,
          } = res;
          if (status) {
            this.setState({
              products,
              searchLoading: false,
              productCount,
              selectedProductIndex: productCount === 1 ? 0 : -1,
              exceedsLimit,
            });
          } else {
            this.setState({
              searchLoading: false,
            });
          }
        }).catch((ex) => {
          if (!ex.name || ex.name !== 'AbortError') {
            console.log(ex);
            this.setState({
              loading: false,
              searchLoading: false,
            });
          } else {
            this.setState({
              searchLoading: false,
            });
          }
        });
      // this.searchController.abort();
    }, 1000);

    this.setState({ timeout });
  }

  getCategories() {
    fetch('/api/categories?parent=true', {
      signal: this.fetchCategoriesController.signal,
    })
      .then((res) => res.json())
      .then((res) => {
        const { categories } = res;
        if (categories?.length) {
          this.setState({
            categories,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }).catch((ex) => {
        if (!ex.name || ex.name !== 'AbortError') {
          console.log(ex);
          this.setState({
            loading: false,
          });
        }
      });
  }

  // showNavigation() {
  //   const { redirectToSearchResults } = this.state;
  //   if (redirectToSearchResults) {
  //     this.setState({
  //       redirectToSearchResults: false,
  //     });
  //   }
  // }

  scrollToSelectedIndex() {
    const { selectedProductIndex, dropdown, dropdown2 } = this.state;
    const list = dropdown.current;
    const list2 = dropdown2.current;
    const item = list.children[selectedProductIndex];
    const item2 = list2.children[selectedProductIndex];
    item?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    item2?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  logoutUser(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    fetch('/api/auth/logout', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const { status, message } = res;
        if (status === 'success') {
          this.setState({ loading: false });
          localStorage.removeItem('image');
          localStorage.removeItem('username');
          localStorage.removeItem('userId');
          window.location.reload(false);
          this.setState({
            user: {},
          });
        } else {
          console.error(message);
        }
      })
      .catch((err) => {
        console.error(err, 'log out error');
        this.setState({ loading: false });
      });
  }

  renderUserDropdown() {
    return (
      <div className="dropleft position-relative">
        <button
          className="btn btn-outline-orange fw-bold glass-container"
          type="button"
          id="userDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          // style={{ color: 'forestgreen' }}
        >
          {/* <i
            className="fa fa-user "
            style={{ width: 34, height: 34 }}//
          /> */}
          <i
            className="fas fa-user"
          />
        </button>
        <ul className="dropdown-menu position-absolute" style={{ left: '-115px' }} aria-labelledby="userDropdown">
          <li>
            <NavLink className="dropdown-item" to="/profile">
              Profils
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="dropdown-item" to="/lists">
              Mani saraksti
            </NavLink>
          </li> */}
          <li>
            <button className="dropdown-item" type="button" onClick={this.logoutUser}>
              Iziet
            </button>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const {
      categories, loading,
      products, substring, searchLoading, openDropdown, dropdown,
      searchInput, dropdown2, searchInput2, productCount, openNavbar,
      navbar, selectedProductIndex, exceedsLimit, user, hideLoginButton,
      hideListsButton,
    } = this.state;

    const { history } = this.props;

    if (products?.length && !products?.some(({ navlink }) => navlink)) {
      products.push({ navlink: true });
    }

    return (
      <>
        <header
          className="container d-flex navbar navbar-expand-lg navbar-light bg-light"
          ref={navbar}
          version="0.0.0.1"
        >
          <div className="col-12 position-relative">
            <div className="d-flex justify-content-center">
              {/* DESKTOP logo */}
              <NavLink
                className="d-none d-md-block text-center mt-1 text-decoration-none"
                to="/"
              >
                <img
                  src={Logo}
                  alt="ProductCave"
                  title="ProductCave"
                  className="img-fluid"
                  style={{ maxWidth: 300 }}
                />
              </NavLink>
              <span className="mt-4 text-orange d-md-block d-none fw-bold">
                2.0 BETA
              </span>
              {/* MOBILE logo */}
              <div className="d-flex justify-content-between align-items-center col-12 d-md-none">
                <div className="d-flex align-items-end">
                  <button
                    className="navbar-toggler border-0 shadow-none d-md-none p-0 me-2"
                    type="button"
                    onClick={() => this.setState({ openNavbar: true })}
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="d-flex align-items-end"
                    style={{ maxWidth: 180 }}
                  >
                    <NavLink
                      className="text-decoration-none col-10"
                      to="/"
                    >
                      <img
                        src={Logo}
                        alt="ProductCave"
                        title="ProductCave"
                        className="img-fluid mb-1"
                      />
                    </NavLink>
                    <span className="text-orange d-md-none fw-bold lh-1 mb-1 text-nowrap hide-xs" style={{ fontSize: 10 }}>
                      2.0 BETA
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center mt-3">
              <form
                className="d-flex border rounded d-lg-none col-12"
                onSubmit={(e) => {
                  e.preventDefault();

                  if (productCount === 1) {
                    this.searchController.abort();
                    this.setState({
                      selectedProductIndex: -1,
                      products: [],
                      openDropdown: false,
                      substring: '',
                    });
                    history.push(`/product/${products[0].slug}`);
                    return;
                  }

                  if ((selectedProductIndex === -1 && substring)) {
                    this.searchController.abort();
                    this.setState({
                      selectedProductIndex: -1,
                      products: [],
                      openDropdown: false,
                      substring: '',
                    });
                    history.push(`/products/?search=${substring}`);
                  }
                }}
              >
                <div className="dropdown">
                  <button
                    className="text-nowrap border-0 p-2 ps-3 pe-3 d-none d-md-block"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Izvēlne
                    <i className="fas fa-angle-down ms-2" />
                  </button>
                  <ul className="dropdown-menu">
                    {/* <li>
                    <NavLink
                      className="dropdown-item d-flex align-items-center"
                      to="/lists"
                    >
                      <span className="ms-2 font-niramit">
                        Saraksti
                      </span>
                    </NavLink>
                  </li> */}
                    {categories?.length ? categories.map((category) => {
                      if (!category?.hidden) {
                        return (
                          <li
                            key={category?.name}
                          >
                            <NavLink
                              className="dropdown-item d-flex align-items-center"
                              to={`/category/${category?.slug}`}
                            >
                              <img
                                src={category?.image}
                                alt={category?.name}
                                title={category?.name}
                                width="30"
                                height="30"
                              />
                              <span className="ms-2 font-niramit">
                                {category?.name}
                              </span>
                            </NavLink>
                          </li>
                        );
                      }
                      return null;
                    }) : null}
                  </ul>
                </div>
                <div
                  className="dropdown w-100"
                >
                  <input
                    className="form-control text-nowrap border-0 p-2 ps-3 pe-3 bg-white shadow-none"
                    id="categories"
                    type="text"
                    name="search"
                    autoComplete="off"
                    value={substring}
                    onChange={(e) => {
                      this.onSearch(e.target.value);
                    }}
                    ref={searchInput2}
                    aria-label="search products"
                  />
                  <div
                    className={`dropdown-menu w-100 ${(openDropdown && products?.length || searchLoading) ? 'show' : 'd-none'}`}
                    style={{
                      overflowY: 'auto',
                      maxHeight: '350px',
                    }}
                    ref={dropdown2}
                  >
                    {searchLoading ? (
                      <div
                        className="spinner-border spinner-border-sm text-dark ms-2 me-2 mt-2"
                        role="status"
                      />
                    ) : null}
                    {(products?.length && !searchLoading) ? products.map((product, index) => {
                      if (product?._id) {
                        return (
                          <div
                          // to={`/product/${product?.slug}`}
                            key={product?._id}
                            role="button"
                            tabIndex={-1}
                            className="text-decoration-none"
                            onClick={() => {
                              this.searchController.abort();
                              this.setState({
                                selectedProductIndex: -1,
                                products: [],
                                openDropdown: false,
                                substring: '',
                              });
                              history.push(`/product/${product?.slug}`);
                            }}
                          >
                            <div
                              className={`rounded-1 m-1 p-1 ps-2 text-dark hover-light d-flex align-items-center
                            ${selectedProductIndex === index ? 'bg-darker' : ''}`}
                            >
                              <img
                                style={{ width: '50px' }}
                                className={`me-2 ${!product?.available ? 'disabled' : ''}`}
                                src={product?.image}
                                alt={product?.name}
                                title={product?.name}
                              />
                              {product?.name}
                            </div>
                          </div>
                        );
                      }
                      return (
                        <SearchAllNavlink
                          key="SearchAllNavlink2"
                          isSelected={selectedProductIndex === products?.length - 1}
                          products={products}
                          search={substring}
                          // selectedShop={selectedShop}
                          productCount={productCount}
                          exceedsLimit={exceedsLimit}
                          onClick={() => {
                            this.setState({
                              selectedProductIndex: -1,
                              products: [],
                              openDropdown: false,
                              substring: '',
                            });
                          }}
                        />
                      );
                    }) : null}
                  </div>
                </div>
                <button
                  type={selectedProductIndex === -1 || productCount === 1 ? 'submit' : 'button'}
                  className="border-0 p-2 ps-3 pe-3"
                  aria-label="Search button"
                >
                  <FaSearch />
                </button>
              </form>
            </div>
            <div
              className={`collapse navbar-collapse flex-column justify-content-center ${openNavbar ? 'open' : null}`}
              id="navbarNavAltMarkup"
            >
              {/* <h1 className="visually-hidden">
              <a href="https://productcave.lv">
                <title>Meklēt produktus!</title>
              </a>
            </h1> */}
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm d-md-none text-dark ms-2 me-2 mt-2"
                  role="status"
                />
              ) : (
                <div
                  className="navbar-nav d-lg-none fw-bold fs-5 mt-lg-0 mt-2 font-baloo-da-2"
                >
                  {/* <NavLink
                  className="dropdown-item d-flex align-items-center"
                  to="/lists"
                >
                  <span className="ms-2 font-niramit">
                    Saraksti
                  </span>
                </NavLink> */}
                  {user?.username ? (
                    <li>
                      <NavLink
                        className="dropdown-item d-flex align-items-center"
                        to="/lists"
                      >
                        <span className="ms-2 font-niramit">
                          Mani saraksti
                        </span>
                      </NavLink>
                    </li>
                  ) : null}
                  {user?.username ? (
                    <li>
                      <NavLink
                        className="dropdown-item d-flex align-items-center"
                        to="/profile"
                      >
                        <span className="ms-2 font-niramit">
                          Mans profils
                        </span>
                      </NavLink>
                    </li>
                  ) : null}
                  {categories?.length ? categories.map((category) => {
                    if (!category.hidden) {
                      return (
                        <NavLink
                          className="nav-link text-grey hover-light rounded ps-1"
                          to={`/category/${category?.slug}`}
                          key={category?._id}
                          onClick={() => this.setState({ openNavbar: false })}
                        >
                          {category.name}
                        </NavLink>
                      );
                    }
                    return null;
                  }) : null}
                </div>
              )}
              <form
                className="border rounded w-50 d-none d-md-flex mb-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (productCount === 1) {
                    this.searchController.abort();
                    this.setState({
                      selectedProductIndex: -1,
                      products: [],
                      openDropdown: false,
                      substring: '',
                    });
                    history.push(`/product/${products[0].slug}`);
                    return;
                  }

                  if ((selectedProductIndex === -1 && substring)) {
                    this.searchController.abort();
                    this.setState({
                      selectedProductIndex: -1,
                      products: [],
                      openDropdown: false,
                      substring: '',
                    });
                    history.push(`/products/?search=${substring}`);
                  }
                }}
              >
                {loading ? (
                  <div className="d-flex align-items-center">
                    <span
                      className="spinner-border spinner-border-sm text-dark ms-2 me-2"
                      role="status"

                    />
                  </div>
                ) : (
                  <>
                    <div className="dropdown">
                      <button
                        className="text-nowrap border-0 p-2 ps-3 pe-3"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Izvēlne
                        <i className="fas fa-angle-down ms-2" />
                      </button>
                      <ul className="dropdown-menu">
                        {/* <li>
                        <NavLink
                          className="dropdown-item d-flex align-items-center"
                          to="/lists"
                        >
                          <span className="ms-2 font-niramit">
                            Saraksti
                          </span>
                        </NavLink>
                      </li> */}
                        {categories?.length ? categories.map((category) => {
                          if (!category?.hidden) {
                            return (
                              <li
                                key={category?.name}
                              >
                                <NavLink
                                  className="dropdown-item d-flex align-items-center"
                                  to={`/category/${category?.slug}`}
                                >
                                  <img
                                    src={category?.image}
                                    alt={category?.name}
                                    title={category?.name}
                                    width="30"
                                    height="30"
                                  />
                                  <span className="ms-2 font-niramit">
                                    {category?.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          }
                          return null;
                        }) : null}
                      </ul>
                    </div>
                  </>
                )}
                <div
                  className="dropdown w-100"
                >
                  <input
                    className="form-control text-nowrap border-0 p-2 ps-3 pe-3 bg-white shadow-none"
                    id="categories"
                    type="text"
                    name="search"
                    autoComplete="off"
                    value={substring}
                    required
                    onChange={(e) => {
                      this.onSearch(e.target.value);
                    }}
                    ref={searchInput}
                    aria-label="search products"
                  />
                  <div
                    className={`dropdown-menu w-100 ${(openDropdown && products && products.length || searchLoading) ? 'show' : 'd-none'}`}
                    style={{
                      overflowY: 'auto',
                      maxHeight: '250px',
                    }}
                    ref={dropdown}
                  >
                    {searchLoading ? (
                      <div
                        className="spinner-border spinner-border-sm text-dark ms-2 me-2 mt-2"
                        role="status"
                      />
                    ) : null}
                    {(products?.length && !searchLoading) ? products.map((product, index) => {
                      if (product?._id) {
                        return (
                          <div
                          // to={`/product/${product?.slug}`}
                            key={product?._id}
                            // className="text-decoration-none"
                            role="button"
                            tabIndex={-1}
                            onClick={() => {
                              this.searchController.abort();
                              this.setState({
                                selectedProductIndex: -1,
                                products: [],
                                openDropdown: false,
                                substring: '',
                              });
                              history.push(`/product/${product?.slug}`);
                            }}
                          >
                            <div
                              className={`rounded-1 m-1 p-1 ps-2 text-dark hover-light d-flex align-items-center
                            ${selectedProductIndex === index ? 'bg-darker' : ''}`}
                            >
                              <img
                                style={{ width: '50px' }}
                                className={`me-2 ${!product?.available ? 'disabled' : ''}`}
                                src={product?.image}
                                alt={product.name}
                                title={product.name}
                              />
                              {product?.name}
                            </div>
                          </div>
                        );
                      }
                      return (
                        <SearchAllNavlink
                          key="SearchAllNavlink2"
                          isSelected={selectedProductIndex === products?.length - 1}
                          products={products}
                          search={substring}
                          // selectedShop={selectedShop}
                          productCount={productCount}
                          exceedsLimit={exceedsLimit}
                          onClick={() => {
                            this.setState({
                              selectedProductIndex: -1,
                              products: [],
                              openDropdown: false,
                              substring: '',
                            });
                          }}
                        />
                      );
                    }) : null}
                  </div>
                </div>
                <button
                  type={selectedProductIndex === -1 || productCount === 1 ? 'submit' : 'button'}
                  className="border-0 p-2 ps-3 pe-3"
                  aria-label="Search button"
                >
                  <FaSearch />
                </button>
              </form>
            </div>
          </div>
        </header>
        {/* STICKY PART */}
        {/* desktop */}
        <div className="d-sm-flex d-none position-absolute z-index-1 pe-2 pt-2 top-0 end-0">
          <div className="align-items-center d-flex flex-row">
            {hideListsButton
              ? null
              : (
                <NavLink
                  className="btn btn-outline-orange fw-bold glass-container"
                  to="/lists"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true" />
                </NavLink>
              )}
            {!user?.username && !hideLoginButton
              ? (
                <NavLink
                  className="btn btn-outline-orange fw-bold ms-1 me-2 glass-container"
                  to="/login"
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  Ienākt
                </NavLink>
              )
              : null}
          </div>
          {user?.username ? (
            <div className="ms-1">
              {this.renderUserDropdown()}
            </div>
          ) : null}
        </div>
        {/* mobile */}
        <div className="d-flex d-sm-none position-absolute z-index-1 pe-2 pt-2 top-0 end-0">
          {hideListsButton ? null : (
            <div className="z-index-2">
              <NavLink
                className="btn btn-outline-orange fw-bold glass-container"
                to="/lists"
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <i
                  className="fa fa-shopping-cart"
                  aria-hidden="true"
                />
              </NavLink>
            </div>
          )}
          {user?.username ? (
            <div className="ms-1">
              {this.renderUserDropdown()}
            </div>
          ) : null}
          {!user?.username && !hideLoginButton
            ? (
              <div className="z-index-2 me-2 ms-1">
                <NavLink
                  className="btn btn-outline-orange fw-bold glass-container"
                  to="/login"
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  {/* Ienākt */}
                  {/* <i className="fas fa-sign-in-alt /> */}
                  {hideListsButton ? 'Ienākt' : (
                    <i className="fas fa-user" />
                  )}
                </NavLink>
              </div>
            )
            : null}
        </div>
      </>
    );
  }
}

Header.propTypes = {
  // user: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // location: PropTypes.object.isRequired,
};

export default withRouter(Header);
