import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
// import SearchAllNavlink from '../../../components/autoComplete/components/searchAllNavlink';
import CenteredSpinner from '../../../components/centeredSpinner';
import PriceChart from './priceChart';

function getBorderColorByConfindence(confidence) {
  if (confidence >= 70) {
    return 'high-confidence';
  } if (confidence >= 40) {
    return 'medium-confidence';
  }
  return 'low-confidence';
}

class AddProductSuggestionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLoading: false,
      modal: React.createRef(),
      searchInput: React.createRef(),
      dropdown: React.createRef(),
      openDropdown: false,
      products: [],
      substring: '',
      // search: '',
      // productCount: 0,
      selectedProduct: null,
      timeout: null,
      selectLoading: false,
      selectedProductIndex: -1,
      submitLoading: false,
      loading: true,
      closestShopsProducts: [],
      refreshPriceChart: false,
    };
    this.searchController = new AbortController();

    this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
    this.handleClickOutsideSearch = this.handleClickOutsideSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onProductSelect = this.onProductSelect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.scrollToSelectedIndex = this.scrollToSelectedIndex.bind(this);
    this.addProductSuggestion = this.addProductSuggestion.bind(this);
    this.getClosestShopProducts = this.getClosestShopProducts.bind(this);
  }

  componentDidMount() {
    this.getClosestShopProducts();
    const { searchInput } = this.state;
    document.addEventListener('mousedown', this.handleClickOutsideSearch);
    document.addEventListener('mousedown', this.handleClickOutsideModal);
    searchInput?.current?.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    const { searchInput } = this.state;
    document.removeEventListener('mousedown', this.handleClickOutsideModal);
    document.removeEventListener('mousedown', this.handleClickOutsideSearch);
    searchInput?.current?.removeEventListener('keydown', this.handleKeyDown);
    this.searchController.abort();
  }

  handleClickOutsideModal(e) {
    const { modal } = this.state;
    const { toggleAddProductSuggestionModal } = this.props;
    if (!modal.current.contains(e.target)) toggleAddProductSuggestionModal({});
  }

  handleKeyDown(event) {
    const { missingShop } = this.props;
    const { selectedProductIndex, products, substring } = this.state;
    if (event.keyCode === 13) {
      // Enter key pressed
      // console.log({ selectedProductIndex, prodLen: products.length - 1 });
      if (selectedProductIndex > -1 && selectedProductIndex !== products.length - 1) {
        this.onProductSelect({ product: products[selectedProductIndex] });
        this.setState({
          selectedProductIndex: -1,
        });
      } else if (selectedProductIndex > -1 && selectedProductIndex === products.length - 1) {
        // const { history } = this.props;
        // history.push(`/products/?search=${substring}&shop=${missingShop?._id}`);
        window.open(`/products/?search=${substring}&shop=${missingShop?._id}`, '_blank');
        this.setState({
          selectedProductIndex: -1,
        });
      }
    } else if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
      if (event.keyCode === 38) {
        // Up arrow key
        this.setState({
          selectedProductIndex: Math.max(0, selectedProductIndex - 1),
        });
        this.scrollToSelectedIndex();
      } else if (event.keyCode === 40) {
        // Down arrow key
        this.setState({
          selectedProductIndex: Math.min(
            products.length - 1,
            selectedProductIndex + 1,
          ),
        });
        this.scrollToSelectedIndex();
      }
    }
  }

  async handleClickOutsideSearch(e) {
    const {
      dropdown, searchInput, openDropdown,
    } = this.state;
    if (
      !dropdown?.current?.contains(e.target)
        && !searchInput?.current?.contains(e.target)
    ) {
      this.setState({
        openDropdown: false,
      });
    } else if (searchInput?.current?.contains(e.target)
    ) {
      this.setState({
        openDropdown: !openDropdown,
      });
    } else {
      this.setState({
        openDropdown: true,
      });
    }
  }

  onProductSelect({ product }) {
    let { closestShopsProducts } = this.state;
    const selectedProductInClosestProducts = closestShopsProducts
      .find((each) => each._id === product._id);

    if (!selectedProductInClosestProducts) {
      // put product in start of closestShopsProducts
      closestShopsProducts = [{ ...product, manuallyAdded: true }, ...closestShopsProducts];
    }

    if (!product) return;
    this.setState({
      openDropdown: false,
      products: [],
      // selectLoading: true,
      substring: '',
      selectedProduct: product,
      closestShopsProducts,
      refreshPriceChart: true,
    }, () => {
      this.setState({
        refreshPriceChart: false,
      });
    });
  }

  onSearch(substring) {
    this.setState({ substring });
    if (substring?.length < 2) {
      this.setState({ products: [], searchLoading: false });
      if (this.searchController) {
        this.searchController.abort();
      }
      return;
    }
    let { timeout } = this.state;
    clearTimeout(timeout);

    if (this.searchController) {
      this.searchController.abort();
      // this.setState({ searchLoading: false });
      this.searchController = new AbortController();
    }

    timeout = setTimeout(() => {
      this.setState({
        // search: substring,
        searchLoading: true,
        openDropdown: true,
      });
      const { missingShop, baseProduct } = this.props;
      const { _id: baseProductId } = baseProduct;

      fetch(`/api/shops-products/autofill?search=${substring}&shopId=${missingShop?._id}&baseProductId=${baseProductId}`, {
        signal: this.searchController.signal,
      })
        .then((res) => res.json())
        .then((res) => {
          const { products, status, productCount } = res;
          if (status) {
            this.setState({
              products,
              searchLoading: false,
              // productCount,
              selectedProductIndex: productCount === 1 ? 0 : -1,
            });
          } else {
            this.setState({
              searchLoading: false,
            });
          }
        }).catch((ex) => {
          if (!ex.name || ex.name !== 'AbortError') {
            console.log(ex);
            this.setState({
              searchLoading: false,
            });
          } else {
            this.setState({
              searchLoading: false,
            });
          }
        });
    }, 1000);
    this.setState({ timeout });
  }

  getClosestShopProducts() {
    const { missingShop, baseProduct } = this.props;

    fetch(`/api/shops-products/closest?shopId=${missingShop._id}&baseProductId=${baseProduct._id}`)
      .then((res) => res.json())
      .then((res) => {
        const {
          status,
          data: closestShopsProducts,
        } = res;
        if (status === 'success') {
          this.setState({
            loading: false,
            closestShopsProducts,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          loading: false,
        });
      });
  }

  addProductSuggestion() {
    const { addProductSuggestion, missingShop, baseProduct } = this.props;
    const { selectedProduct } = this.state;
    this.setState({
      submitLoading: true,
    });
    fetch('/api/products-suggestions/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopsProductId: selectedProduct._id,
        shopId: missingShop._id,
        baseProductId: baseProduct._id,
      }),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        const {
          status,
          products,
          missingShops,
          // productSuggestion,
        } = res;
        if (status === 'success') {
          addProductSuggestion({ products, missingShops });
          this.setState({
            submitLoading: false,
          });
        } else {
          this.setState({
            submitLoading: false,
          });
        }
      }).catch((ex) => {
        console.log(ex);
        this.setState({
          submitLoading: false,
        });
      });
  }

  scrollToSelectedIndex() {
    const { selectedProductIndex, dropdown } = this.state;
    const list = dropdown.current;
    const item = list.children[selectedProductIndex];
    item?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  render() {
    const {
      modal, searchInput, openDropdown,
      searchLoading, dropdown,
      selectedProduct,
      selectLoading,
      selectedProductIndex,
      products,
      substring,
      // productCount,
      submitLoading,
      loading,
      closestShopsProducts,
      refreshPriceChart,
    } = this.state;
    const {
      missingShop,
      toggleAddProductSuggestionModal,
      isOpen,
      baseProduct,
    } = this.props;

    const samePrices = baseProduct?.minPrice === baseProduct?.maxPrice;
    const isWeb = window.innerWidth > 768;

    return (
      <div
        className={`modal ${isOpen ? 'show' : ''}`}
        style={{
          display: isOpen ? 'block' : 'none',
        }}
      >
        <div className="modal-backdrop z-index-1" style={{ display: isOpen ? 'block' : 'none' }} />
        <div className="modal-dialog modal-dialog-centered z-index-2" style={{ maxWidth: 700 }}>
          <div
            className="modal-content"
            ref={modal}
            style={{
              // overflow: 'hidden',
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title d-flex align-items-center">
                Pievieno
                {' '}
                <img src={missingShop.image} className="mx-2" width={80} alt={missingShop.name} />
                ieteikumu
              </h5>
              <button
                type="button"
                className="btn-close  shadow-none"
                aria-label="Close"
                onClick={toggleAddProductSuggestionModal}
              />
            </div>
            <div className="modal-body">
              {loading ? <CenteredSpinner /> : (
                <div>
                  {!selectLoading ? (
                    <div className="d-flex flex-sm-row flex-column border-bottom align-items-center">

                      <div
                        className="col-sm-3 col-4 d-flex flex-column
                            align-items-center justify-content-center"
                        style={{
                          minWidth: 150,
                        }}
                      >
                        <div className="d-block fw-bold mx-auto">
                          {samePrices
                            ? baseProduct?.minPrice : (
                              <>
                                {baseProduct?.minPrice}
                                -
                                {baseProduct?.maxPrice}
                              </>
                            )}
                          €
                          {baseProduct?.unit ? ` /${baseProduct?.unit}` : null}
                        </div>
                        <img src={baseProduct.image} alt="" className="col-12" />
                        <span className="d-sm-block d-none text-center small fw-bold mt-1">{baseProduct?.name}</span>
                      </div>
                      <span className="d-sm-none d-block text-center small fw-bold mt-1">{baseProduct?.name}</span>
                      <div className="col-sm-9 col-12 d-flex  justify-content-center">
                        <PriceChart
                          minimalistic
                          period="6months"
                          baseProductId={baseProduct._id}
                          additionalShopProductId={selectedProduct?._id}
                          refreshPriceChart={refreshPriceChart}
                          selectedShop={missingShop}
                          hideSuggestions
                        />
                      </div>
                      {/* price chart */}
                    </div>
                  ) : null}
                  <div
                    className="d-flex border rounded mt-3 col-12"
                  >
                    <div
                      className="dropdown w-100"
                    >
                      <input
                        className="form-control text-nowrap border-0 p-2 ps-3 pe-3 bg-white shadow-none"
                        id="categories"
                        type="text"
                        name="search"
                        autoComplete="off"
                        // add autofocus only on web
                        autoFocus={isWeb}
                        onChange={(e) => {
                          this.onSearch(e.target.value);
                        }}
                        placeholder={`meklē kategorijā "${baseProduct?.masterCategory?.name}"`}
                        ref={searchInput}
                        value={substring}
                      />
                      <div
                        className={`dropdown-menu w-100 ${(openDropdown && products?.length || searchLoading) ? 'show' : 'd-none'}`}
                        style={{
                          overflowY: 'auto',
                          maxHeight: '350px',
                        }}
                        ref={dropdown}
                      >
                        {searchLoading ? (
                          <div
                            className="spinner-border spinner-border-sm text-dark ms-2 me-2 mt-2"
                            role="status"
                          />
                        ) : null}
                        {(products?.length && !searchLoading) ? products.map((each, index) => (
                          <div
                            key={each?._id}
                            role="button"
                            tabIndex={0}
                            onClick={() => this.onProductSelect({ product: each })}
                            onKeyDown={({ keyCode }) => {
                              if (keyCode === 13) {
                                this.onProductSelect({ product: each });
                              }
                            }}
                            title={each?.name}
                          >
                            <div
                              className={`rounded-1 m-1 p-1 ps-2 small
                                 text-dark hover-light d-flex justify-content-between
                                 ${selectedProductIndex === index ? 'bg-darker' : ''}`}
                            >
                              <div className="d-flex align-items-center col-10">
                                <img
                                  style={{ width: '50px' }}
                                  className="me-2"
                                  src={each?.image?.image}
                                  alt=""
                                />
                                <span className="text-truncate">
                                  {/* {truncateName(each?.name)} */}
                                  {each?.name}
                                </span>
                              </div>
                              <div className="col-2 d-flex flex-column align-items-end">
                                {each?.lastPrice
                                  ? `${each?.lastPrice}€ `
                                  : null}
                                {each?.shop ? (
                                  <img
                                    src={each?.shop?.image}
                                    alt={each?.shop?.name}
                                    style={{ maxWidth: 30 }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))
                          : null }
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="border-0 p-2 ps-3 pe-3"
                      label="Meklēt"
                    >
                      <FaSearch />
                    </button>
                  </div>
                  {selectLoading
                    ? (
                      <div
                        className="spinner-border spinner-border-sm text-dark ms-2 me-2 mt-2"
                        role="status"
                      />
                    )
                    : null}
                  <div>
                    {closestShopsProducts?.length ? (
                      <div className="mt-2">
                        <h5>
                          vai meklē šeit:
                        </h5>
                        <div className="d-flex table-responsive">
                          {closestShopsProducts.map((closestProduct) => {
                            const { _id, manuallyAdded } = closestProduct;
                            const isSelected = selectedProduct?._id === _id;
                            return (
                              <div
                                className="px-1 position-relative"
                                key={closestProduct._id}
                                style={{
                                  minWidth: 130,
                                  maxWidth: 200,
                                }}
                              >
                                {manuallyAdded
                                  ? (
                                    <button
                                      type="button"
                                      className="btn-close small position-absolute top-0 end-0 pe-3 pt-1 shadow-none"
                                      aria-label="Close"
                                      onClick={() => {
                                        const filteredShopProducts = closestShopsProducts
                                          .filter((each) => each._id !== _id);

                                        this.setState({
                                          closestShopsProducts: filteredShopProducts,
                                          selectedProduct: isSelected ? null : selectedProduct,
                                          refreshPriceChart: isSelected,
                                        });
                                      }}
                                      style={{ zIndex: 2 }}
                                    />
                                  )
                                  : null}
                                <div
                                  className={`h-100 border ${isSelected ? 'shadow-sm' : ''} rounded-3 d-flex flex-column justify-content-between product-container`}
                                  onClick={() => this.onProductSelect({
                                    product: closestProduct,
                                  })}
                                  tabIndex={0}
                                  role="button"
                                >
                                  <div className="col-12 d-flex justify-content-between px-1 pt-1">
                                    {closestProduct?.confidence ? (
                                      <div
                                        style={{ height: 35, width: 35 }}
                                        className={`border border-2 rounded-circle d-flex 
                                justify-content-center align-items-center small text-nowrap
                                 ${getBorderColorByConfindence(closestProduct.confidence)}
                                    `}
                                      >
                                        {closestProduct.confidence}
                                        %
                                      </div>
                                    ) : null}
                                    <div>
                                      {closestProduct?.lastPrice ? (
                                        <span className={`${isSelected ? 'fw-bold' : ''}`}>
                                          {closestProduct?.lastPrice}
                                          €
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="image-container text-center">
                                    <img src={closestProduct?.image?.image} alt="" className="col-10" />
                                  </div>
                                  <span className={`text-center small mt-1 ${isSelected ? 'fw-bold' : ''} `}>{closestProduct?.name}</span>
                                  <span className="hover-effect">+</span>
                                </div>
                              </div>
                            );
                          })}

                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.addProductSuggestion();
                }}
                disabled={!selectedProduct || selectLoading || submitLoading}
              >
                {submitLoading ? (
                  <div className="spinner-grow spinner-grow-sm mx-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : 'PIEVIENOT'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddProductSuggestionModal.propTypes = {
  missingShop: PropTypes.object.isRequired,
  toggleAddProductSuggestionModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addProductSuggestion: PropTypes.func.isRequired,
  baseProduct: PropTypes.object.isRequired,
  // selectedShop: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
export default withRouter(AddProductSuggestionModal);
