import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { Rating } from 'react-simple-star-rating';
import StoreIcon from '../img/store_1.png';
import SuggestionProductTooltip from './tooltips/SuggestionProductTooltip';
import AddToListsDropdown from './addToListsDropdown';
import AddProductToList from './addProductToList';

class ProductCard extends Component {
  constructor() {
    super();
    this.state = ({
      ref: React.createRef(),
      ref1: React.createRef(),
      isHovered: false,
      shouldSlideDown: false,
    });

    this.handleHover = this.handleHover.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.checkText = this.checkText.bind(this);
    this.handleClickOnProduct = this.handleClickOnProduct.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkText);
    this.checkText();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkText);
  }

  handleHover() {
    this.setState({
      isHovered: true,
    });
  }

  handleLeave() {
    this.setState({
      isHovered: false,
    });
  }

  handleClickOnProduct(e) {
    const { target } = e;
    const clickedOnUrl = target.closest('#shops-url') !== null;
    const clickedOnDeleteSuggestion = target.closest('#delete-suggestion') !== null;
    const clickedOnSuggestionTooltip = target.closest('#suggestion-tooltip') !== null;

    if (clickedOnUrl || clickedOnDeleteSuggestion || clickedOnSuggestionTooltip) return;
    const { onClick, product } = this.props;

    onClick({
      selectedProduct: product,
      shop: product?.shop,
      shopsProduct: product?.shop ? product : null,
    });
  }

  checkText() {
    const { ref, ref1 } = this.state;
    let { current } = ref;
    if (!current) {
      const { current: current1 } = ref1;
      current = current1;
    }
    const lineHeight = parseInt(getComputedStyle(current).lineHeight, 10);
    const textHeight = current.clientHeight;

    this.setState({
      shouldSlideDown: textHeight > (lineHeight * 2),
    });
  }

  render() {
    const {
      product, hideShop, redirect, searchCount, className,
      disabled, showRange, showPrice, toggleDeleteProductSuggestionModal,
      allowAddToList, lists,
      handleProductChanges, qtyLoading,
    } = this.props;

    const hasMultipleLists = Object.keys(lists)?.length > 1;

    const {
      isHovered, shouldSlideDown, ref, ref1,
    } = this.state;

    if (!product) return null;

    const { suggestion } = product;

    const suggestionStatus = suggestion?.status || product?.suggestionStatus;

    const textStyles = {
      fontWeight: 500,
      transition: shouldSlideDown ? 'transform 2s ease-out' : 'none',
      // eslint-disable-next-line no-nested-ternary
      transform: shouldSlideDown ? (isHovered ? 'translateY(-63%)' : 'none') : 'none',
    };

    if (redirect) {
      return (
        <Link
          to={{
            pathname: `/product/${product.slug}`,
            state: { searchCount },
          }}
          className={`p-1 py-0 p-lg-2 d-flex flex-column mb-4 justify-content-between text-decoration-none text-dark ${className}`}
        >
          <div
            className="h-100 d-flex flex-column justify-content-between
            shadow rounded-3 align-items-center bg-white position-relative"
            onMouseEnter={this.handleHover}
            onMouseLeave={this.handleLeave}
          >
            <div
              className="d-flex flex-column align-items-start fw-bold py-1 px-1 w-100"
            >
              <div className="d-flex justify-content-between col-12">
                <div
                  className="d-flex align-items-center border rounded-5 px-2 py-1 fs-5"
                  title={`atrodams ${product?.shops.filter(({ available }) => available)?.length} veikalos`}
                >
                  <img
                    src={StoreIcon}
                    alt="Saraksti"
                    title="Mani saraksti"
                    className={`me-2 ${disabled || !product.available ? 'disabled' : ''}`}
                  />
                  {product?.shops?.filter(({ available }) => available)?.length}
                </div>
                <div className="fs-4 text-end font-petrona fw-bold">
                  {product.available || showPrice
                    ? `${Number(product.bestPrice).toFixed(2)}€`
                    : ''}
                </div>
              </div>
              <div className={`d-flex mt-1 border rounded-5 px-2 py-1 fs-5 ${disabled || !product.available ? 'disabled' : ''}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="me-2"
                  fill="#ff982e"
                  stroke="#ff982e"
                  strokeWidth="2px"
                  strokeLinejoin="round"
                >
                  <path d="M12 .288l2.833 8.718h9.167l-7.417 5.389 2.833 8.718-7.416-5.388-7.417 5.388 2.833-8.718-7.416-5.389h9.167z" />
                </svg>
                {product?.rating?.toFixed()}
                /5
              </div>
            </div>
            {!hideShop ? (
              <div className="position-absolute top-0 start-0 m-2 fs-5 col-4">
                <img
                  src={product.shop?.image}
                  alt={product.shop?.name}
                  title={product.shop?.name}
                  className="w-100"
                />
              </div>
            ) : null}
            <div
              className="position-relative w-100 text-center"
              style={{ minHeight: '130px' }}
            >
              <img
                src={product?.image}
                className={`w-100 px-2 ${disabled || !product.available ? 'disabled' : null}`}
                alt={product?.name}
                title={product?.name}
                style={{ objectFit: 'contain', maxHeight: '160px', maxWidth: '100%' }}
              />
              {allowAddToList && hasMultipleLists ? (
                <AddToListsDropdown
                  baseProduct={product}
                  lists={lists}
                  handleProductChanges={handleProductChanges}
                  className="position-absolute end-0 bottom-0 d-flex justify-content-end"
                  loading={qtyLoading}
                />
              ) : null}

              {allowAddToList && !hasMultipleLists ? (
                <AddProductToList
                  baseProduct={product}
                  list={lists?.[Object.keys(lists)[0]] || {}}
                  handleProductChanges={handleProductChanges}
                  className="position-absolute end-0 bottom-0 d-flex justify-content-end"
                  loading={qtyLoading}
                />
              ) : null}

            </div>
            <div
              className="d-flex flex-column align-items-center mt-1"
              style={{ maxHeight: '3.3em', overflow: 'hidden' }}
            >
              <div
                className="text-center mb-4 font-montserrat px-1 text-break product-name-link fs-6"
                title={product.name}
                style={textStyles}
                ref={ref}
              >
                {product?.name}
              </div>
            </div>

          </div>
        </Link>
      );
    }
    return (
      <div
        className={`d-flex flex-column justify-content-between text-decoration-none text-dark ${className}`}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleLeave}
        style={{ maxHeight: '320px' }}
        role="button"
        tabIndex={-1}
        onClick={this.handleClickOnProduct}
      >
        <div
          className="h-100 d-flex flex-column justify-content-between
            shadow-sm rounded-3 align-items-center bg-white position-relative"
        >
          {suggestionStatus ? (
            <div className="d-flex justify-content-between col-12 position-absolute">
              <div id="suggestion-tooltip">
                <SuggestionProductTooltip status={suggestionStatus} />
              </div>
              {suggestion ? (
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  id="delete-suggestion"
                  style={{
                    fontSize: 10,
                  }}
                  onClick={() => toggleDeleteProductSuggestionModal(product)}
                  title="Noņemt ieteikumu"
                />
              ) : null}
            </div>
          ) : null}
          <div
            className="d-flex justify-content-between flex-wrap pt-2 pe-1 col-11"
            style={{ height: '55px' }}
          >
            {(!hideShop && product?.shop?.name) ? (
              <div className="col-5 text-center d-flex align-items-center position-relative">
                <img
                  src={product?.shop?.image}
                  alt={product?.shop?.name}
                  title={product?.shop?.name}
                  className={`col-12 ${disabled ? 'disabled' : null}`}
                />
              </div>
            ) : null}
            <div
              className={`fw-bold fs-4 text-end font-petrona d-none d-md-block ${product?.shop?.image ? 'col-6' : 'col-12'}`}
            >
              {(showRange
              && Number(product.minPrice).toFixed(2) !== Number(product.maxPrice).toFixed(2))
                ? (
                  <span>
                    {Number(product.minPrice).toFixed(2)}
                    -
                    {Number(product.maxPrice).toFixed(2)}
                    €
                  </span>
                ) : (
                  <span>
                    {Number(product.minPrice).toFixed(2)}
                    €
                  </span>
                )}
            </div>
            <div
              className={`fs-4 text-end font-petrona d-md-none ${product?.shop?.image ? 'col-6' : 'col-12'}`}
            >
              {(showRange
              && Number(product.minPrice).toFixed(2) !== Number(product.maxPrice).toFixed(2))
                ? (
                  <b>
                    {Number(product.minPrice).toFixed(2)}
                    -
                    {Number(product.maxPrice).toFixed(2)}
                    €
                  </b>
                ) : (
                  <b>
                    {Number(product.minPrice).toFixed(2)}
                    €
                  </b>
                )}
            </div>
          </div>
          <div>
            <img
              src={product?.image}
              className={`img-fluid px-2 ps-xxl-3 pe-xxl-3 ${disabled ? 'disabled' : null}`}
              alt={product?.name}
              title={product?.name}
              style={{ objectFit: 'contain', maxHeight: '200px' }}
            />
          </div>
          <div
            className="d-flex flex-column align-items-center mt-1"
            style={{ maxHeight: '3.3em', overflow: 'hidden' }}
          >
            <div
              className="text-center font-montserrat px-1 text-break product-name-link fs-6"
              title={product?.name}
              style={textStyles}
              ref={ref1}
            >
              {product?.name}
            </div>
          </div>
          {product?.shopsUrl
            ? (
              <a
                href={product?.shopsUrl}
                target="_blank"
                className="position-absolute bottom-0 end-0 link-hover
                  rounded-circle p-1 d-flex align-items-center justify-content-center"
                style={{ marginBottom: '-10px', marginRight: '-10px' }}
                rel="noreferrer"
                id="shops-url"
                label={product.name}
              >
                <i
                  className="fas fa-link fs-5"
                  style={{ padding: 1 }}
                />
              </a>
            )
            : null}
        </div>
      </div>
    );
  }
}

ProductCard.defaultProps = {
  hideShop: false,
  redirect: false,
  searchCount: false,
  className: '',
  disabled: false,
  showRange: false,
  showPrice: false,
  toggleDeleteProductSuggestionModal: () => {},
  onClick: () => {},
  allowAddToList: false,
  lists: {},
  handleProductChanges: () => {},
  qtyLoading: false,
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  hideShop: PropTypes.bool,
  redirect: PropTypes.bool,
  searchCount: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showRange: PropTypes.bool,
  showPrice: PropTypes.bool,
  toggleDeleteProductSuggestionModal: PropTypes.func,
  onClick: PropTypes.func,
  allowAddToList: PropTypes.bool,
  lists: PropTypes.object,
  handleProductChanges: PropTypes.func,
  qtyLoading: PropTypes.bool,
};

export default ProductCard;
